// src/firebaseConfig.js v1.0.0

import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBOEogSCvqvX89KotxjC-j_U_hRYJIHe1U",
  authDomain: "tkcgroup-portfolio.firebaseapp.com",
  projectId: "tkcgroup-portfolio",
  storageBucket: "tkcgroup-portfolio.appspot.com",
  messagingSenderId: "229395257895",
  appId: "1:229395257895:web:da7e39af0073d0f594cc02",
  measurementId: "G-6QENFR1YYY"
};

const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 4069);
  connectAuthEmulator(auth, "http://localhost:3069");
}
