// /watch/src/components/MultiPlayer.js v1.4.0

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { httpsCallable } from 'firebase/functions';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { functions } from '../firebaseConfig';
import { useUser } from '../UserContext';
import '../App.css';
import { Button, TextField, List, ListItem, ListItemText } from '@mui/material';

function MultiPlayer() {
  const [messages, setMessages] = useState([]);
  const [sessionId, setSessionId] = useState('');
  const [shareableLink, setShareableLink] = useState('');
  const [userInput, setUserInput] = useState('');
  const { user } = useUser();
  const welcomeMessageAddedRef = useRef(false);
  const setWelcomeBackMessage = useRef(true);
  const [error, setError] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const [selectedEra, setSelectedEra] = useState('');
  const [selectedMood, setSelectedMood] = useState('');
  const [sessionSubmitted, setSessionSubmitted] = useState(false);
  const [lastUserCount, setLastUserCount] = useState(0);

  const genres = [
    { emoji: '💥', name: 'Action' },
    { emoji: '🏔️', name: 'Adventure' },
    { emoji: '🧸', name: 'Animation' },
    { emoji: '😂', name: 'Comedy' },
    { emoji: '🕵️‍♂️', name: 'Crime' },
    { emoji: '📽️', name: 'Documentary' },
    { emoji: '🎭', name: 'Drama' },
    { emoji: '👪', name: 'Family' },
    { emoji: '🔮', name: 'Fantasy' },
    { emoji: '📖', name: 'History' },
    { emoji: '🔪', name: 'Horror' },
    { emoji: '🎶', name: 'Music' },
    { emoji: '🔍', name: 'Mystery' },
    { emoji: '💋', name: 'Romance' },
    { emoji: '👽', name: 'Science Fiction' },
    { emoji: '📺', name: 'TV Movie' },
    { emoji: '😱', name: 'Thriller' },
    { emoji: '🪖', name: 'War' },
    { emoji: '🏜️', name: 'Western' }
  ];

  const eras = [
    { emoji: '🎥', name: 'Pre-1920s - The Silent Film Era' },
    { emoji: '🎩', name: '1920s - The Roaring Twenties' },
    { emoji: '🎬', name: '1930s - The Great Depression Era' },
    { emoji: '✈️', name: '1940s - The War Years' },
    { emoji: '🎷', name: '1950s - The Fabulous Fifties' },
    { emoji: '🌼', name: '1960s - The Swinging Sixties' },
    { emoji: '🕺', name: '1970s - The Revolutionary Seventies' },
    { emoji: '📼', name: '1980s - The Awesome Eighties' },
    { emoji: '📞', name: '1990s - The Nineties' },
    { emoji: '🌐', name: '2000s - The New Millennium' },
    { emoji: '📱', name: '2010s - The Modern Era' },
    { emoji: '🖥️', name: '2020s - The Streaming Revolution' }
  ];

  const moods = [
    { emoji: '😊', name: 'Feel-Good' },
    { emoji: '😢', name: 'Tear-Jerker' },
    { emoji: '😂', name: 'Funny' },
    { emoji: '🤯', name: 'Mind-Bending' },
    { emoji: '🎉', name: 'Uplifting' },
    { emoji: '🔥', name: 'Exciting' },
    { emoji: '💞', name: 'Romantic' },
    { emoji: '🙈', name: 'Scary' },
    { emoji: '🧐', name: 'Thought-Provoking' },
    { emoji: '⚡', name: 'Action-Packed' },
    { emoji: '🕰️', name: 'Nostalgic' },
    { emoji: '🌌', name: 'Dreamy' },
    { emoji: '🧙‍♂️', name: 'Magical' },
    { emoji: '🕵️', name: 'Mysterious' },
    { emoji: '👽', name: 'Sci-Fi' },
    { emoji: '💔', name: 'Heartbreaking' },
    { emoji: '👑', name: 'Epic' },
    { emoji: '🎶', name: 'Musical' },
    { emoji: '👊', name: 'Motivational' }
  ];

  const db = getFirestore();

  const handleSelect = (type, value) => {
    let newValue = value;
    if (type === 'era') {
      newValue = value.split(' ')[0]; // Extract the year part only for era
    }
    setUserInput(prev => {
      const parts = prev.split(', ').filter(part => part);
      if (type === 'genre' && parts[0]) parts[0] = newValue;
      else if (type === 'era' && parts[1]) parts[1] = newValue;
      else if (type === 'mood' && parts[2]) parts[2] = newValue;
      else parts.push(newValue);
      return parts.join(', ');
    });
    addMessage({ author: 'user', text: `Selected ${type}: ${newValue}` });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionParam = urlParams.get('sessionID');
    if (sessionParam) {
      setSessionId(sessionParam);
      console.log("Session ID set from URL:", sessionParam);
      addMessage({ author: 'bot', text: 'Session ID found in URL. Joining existing session...' });
    }
  }, []);

  const addMessage = useCallback((message) => {
    console.log("Adding message:", message);
    setMessages(prev => [...prev, message]);
  }, []);

  useEffect(() => {
    if (!welcomeMessageAddedRef.current) {
      addMessage({ author: 'bot', text: 'Welcome to this handy dandy AI-enabled movie picker! To start, choose a genre, era and mood below.' });
      welcomeMessageAddedRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      const sessionRef = doc(getFirestore(), "sessions", sessionId);
      const unsubscribe = onSnapshot(sessionRef, (doc) => {
        const data = doc.data();
        if (data && data.finalSuggestion) {
          console.log("New final suggestion received:", data.finalSuggestion);
          addMessage({ author: 'bot', text: `Movie Night suggestion: ${data.finalSuggestion}` });
        }
      });
  
      return () => unsubscribe(); // Clean up the listener when the component unmounts or sessionId changes
    }
  }, [sessionId]);

  const handlePreferenceSubmit = async () => {
    console.log("Starting handlePreferenceSubmit function.");
    if (!sessionId) {
      console.log("No session ID found, creating a new session.");
      const sessionCreationResult = await createSession();
      if (!sessionCreationResult.success) {
        console.error("Failed to create a new session.");
        addMessage({ author: 'bot', text: 'Failed to create a new session. Please try again.' });
        return;
      }
      setSessionId(sessionCreationResult.sessionId);
      setShareableLink(`${window.location.origin}/?sessionID=${sessionCreationResult.sessionId}`);
      addMessage({ author: 'bot', text: 'New session created successfully!' });
  
      // After creating the session, proceed to add user to session and fetch suggestions
      await addUserToSessionAndFetchSuggestions(sessionCreationResult.sessionId);
    } else {
      console.log("Session ID exists, processing preferences.");
      await addUserToSessionAndFetchSuggestions(sessionId);
    }
  };
  
  const addUserToSessionAndFetchSuggestions = async (sessionId) => {
    const addUserToSession = httpsCallable(functions, 'addUserToSession');
    const fetchMovieSuggestions = httpsCallable(functions, 'fetchAndUpdateMovieSuggestions');
  
    const [genre, era, mood] = userInput.split(',').map(s => s.trim());
    const preferences = { genre, era, mood };
    const userData = {
      id: user.uid,
      name: user.displayName || 'User Name'
    };
  
    const addUserResult = await addUserToSession({ sessionId, user: userData, preferences });
    if (!addUserResult.data.success) {
      console.error("Failed to add user to session:", addUserResult.data.message);
      addMessage({ author: 'bot', text: `Failed to add user to session: ${addUserResult.data.message}` });
      return;
    }

  
    const suggestionsResult = await fetchMovieSuggestions({ sessionId });
    if (suggestionsResult.data.success) {
      handleMovieSuggestions(suggestionsResult.data.movieSuggestions, user.uid);
    } else {
      console.error("Failed to fetch and update movie suggestions:", suggestionsResult.data.message);
    }
  };
  
  const handleMovieSuggestions = (suggestions, userId) => {
    // Log the entire suggestions array and the userId for debugging
    console.log("All suggestions:", suggestions);
    console.log("Current userId:", userId);
  
    // Find the current user's suggestions based on their userId
    const currentUserSuggestions = suggestions.find(suggestion => suggestion.UID === userId);
  
    if (!currentUserSuggestions || !currentUserSuggestions.movieSuggestions) {
      addMessage({ author: 'bot', text: 'No movie suggestions found for your preferences.' });
      return;
    }
  
    // Assuming movieSuggestions is now correctly an array of strings
    const formattedSuggestions = currentUserSuggestions.movieSuggestions.join(', ');
  
    addMessage({ author: 'bot', text: `Based on your preferences, these are the movies I think you may like: ${formattedSuggestions}` });
    addMessage({ author: 'bot', text: `If you'd like to invite another friend to your Movie Night! just share with them this link: ${shareableLink}` });
  };
  

  const createSession = async () => {
    try {
      const createSessionCallable = httpsCallable(functions, 'createSession');
      const result = await createSessionCallable({});
      if (result.data.success) {
        setShareableLink(`${window.location.origin}/?sessionID=${result.data.sessionId}`);
        return { success: true, sessionId: result.data.sessionId };
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      setError(error.message);
      addMessage({ author: 'bot', text: `Failed to create a new session: ${error.message}` });
      return { success: false, message: error.message };
    }
  };

  const submitSession = async () => {
    const submitSessionCallable = httpsCallable(functions, 'submitSession');
    const result = await submitSessionCallable({ sessionId });
    console.log("Session submission result:", result);
    if (result.data.success) {
      addMessage({ author: 'bot', text: 'Session submitted successfully!' });
      addMessage({ author: 'bot', text: `${result.data.finalSuggestion}` });
      setSessionSubmitted(true); // Update state to indicate session is submitted
    } else {
      console.error("Failed to submit session:", result.data.message);
      addMessage({ author: 'bot', text: `Failed to submit session: ${result.data.message}` });
    }
  };

  const handlePlayAgain = async () => {
    // Reset state
    setMessages([]);
    setSessionId('');
    setShareableLink('');
    setUserInput('');
    setWelcomeBackMessage(true);
    setSessionSubmitted(false); // Reset session submitted state

    addMessage({ author: 'bot', text: 'New Movie Night created! You can start adding preferences!' });
  };

  const handleUserSuggestions = (sessionData) => {
    if (sessionData.users) {
      const userIndex = sessionData.users.findIndex(u => u.UID === user.uid);
      if (userIndex === -1) {
        addMessage({ author: 'bot', text: 'User not found in session.' });
        return;
      }
      const userMovieSuggestions = sessionData.users[userIndex].movieSuggestions;
      if (userMovieSuggestions && userMovieSuggestions.length > 0) {
        addMessage({ author: 'bot', text: `Based on your preferences, these are the three movies I think you may like: ${userMovieSuggestions.join(', ')}` });
      } else {
        addMessage({ author: 'bot', text: 'No movie suggestions found. Please try again.' });
      }
    }
  };
  
  const handleSessionUpdates = (sessionData) => {
    if (sessionData.users && sessionData.users.length > lastUserCount) {
      handleNewUserJoin();
      setLastUserCount(sessionData.users.length);
    }
    if (sessionData.allPreferencesSubmitted) {
      handleAllPreferencesSubmitted(sessionData.users.length);
    }
    if (sessionData.finalSuggestion) {
      handleFinalSuggestion(sessionData.finalSuggestion);
    }
  };
  
  const handleNewUserJoin = () => {
    addMessage({ author: 'bot', text: "Awesome! It looks like someone just joined in on your little adventure. I'll let you know once they've suggested their movies." });
  };
  
  const handleAllPreferencesSubmitted = (userCount) => {
    addMessage({ author: 'bot', text: `All participants have submitted their preferences. Total participants: ${userCount}.` });
  };
  
  const handleFinalSuggestion = (finalSuggestion) => {
    addMessage({ author: 'bot', text: `Awesome! Let me get to work...` });
    addMessage({ author: 'bot', text: finalSuggestion });
  };
  
  return (
    <div>
      <h1>Multi-Player Movie Session</h1>
      <List>
        {messages.map((msg, index) => (
          <ListItem key={index}>
            <ListItemText primary={<strong>{msg.author === 'user' ? 'You' : 'Bot'}:</strong>} secondary={msg.text} />
          </ListItem>
        ))}
      </List>
      <TextField
        value={userInput}
        readOnly
        fullWidth
        variant="outlined"
        margin="normal"
        placeholder="Your selections will appear here"
      />
      <Button onClick={handlePreferenceSubmit} variant="contained" color="primary">Submit Preferences</Button>
      <div className="preference">
      {sessionId && <p>Session ID: {sessionId}</p>}
      <p>Shareable Link: <a href={shareableLink}>{shareableLink}</a></p>
      <Button onClick={sessionSubmitted ? handlePlayAgain : submitSession} variant="contained" color="secondary">
        {sessionSubmitted ? 'Play Again' : 'Movie Night!'}
      </Button>
        <h3>Genre</h3>
        {genres.map(genre => (
          <Button key={genre.name} variant="outlined" onClick={() => handleSelect('genre', genre.name)}>
            {genre.emoji} {genre.name}
          </Button>
        ))}
      </div>
      <div className="preference">
        <h3>Era</h3>
        {eras.map(era => (
          <Button key={era.name} variant="outlined" onClick={() => handleSelect('era', era.name)}>
            {era.emoji} {era.name}
          </Button>
        ))}
      </div>
      <div className="preference">
        <h3>Mood</h3>
        {moods.map(mood => (
          <Button key={mood.name} variant="outlined" onClick={() => handleSelect('mood', mood.name)}>
            {mood.emoji} {mood.name}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default MultiPlayer;
