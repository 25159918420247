import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useUser } from '../UserContext';

function AuthDialog({ open, onClose }) {
    const { login, signup } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(true); // State to toggle between login and signup

    const handleAuthAction = async () => {
        try {
            if (isLogin) {
                const userCredential = await login(email, password);
                console.log(`Logged in with UID: ${userCredential.user.uid}`);
            } else {
                const userCredential = await signup(email, password);
                console.log(`Signed up with UID: ${userCredential.user.uid}`);
            }
            onClose(); // Close dialog after successful action
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                alert('No user found, please sign-up.');
            } else {
                console.error('Authentication error:', error);
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{isLogin ? 'Sign In' : 'Sign Up'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleAuthAction}>{isLogin ? 'Login' : 'Sign Up'}</Button>
                <Button onClick={() => setIsLogin(!isLogin)}>
                    {isLogin ? 'Need to create an account?' : 'Already have an account?'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AuthDialog;
