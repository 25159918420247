import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import AuthDialog from './components/AuthDialog';
import MultiPlayer from './components/MultiPlayer';
import './App.css';

function App() {
  const [authOpen, setAuthOpen] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    console.log('Application started');
    if (user) {
      console.log(`Logged in as UID: ${user.uid}`);
    } else {
      console.log('Not logged in');
    }
  }, [user]);

  return (
    <div className="App">
      <header>
        <button onClick={() => setAuthOpen(true)}>Open Login</button>
      </header>
      <AuthDialog open={authOpen} onClose={() => setAuthOpen(false)} />
      <main>
        <MultiPlayer />
      </main>
    </div>
  );
}

export default App;